.editor {
  position: relative;
  border-bottom: 2px solid hsl(var(--twc-background-neutralDark));
  width: 100%;
}

#editor-wrapper {
  border: 2px solid hsl(var(--twc-background-neutralDark));
  border-radius: 4px;

  &.error {
    border-color: hsl(var(--twc-foreground-danger));
  }
}

.menu {
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 8px;
  width: calc(100% - 4px);
  height: 40px;
  margin: 0;
  padding: 0 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid hsl(var(--twc-background-neutralDark));
  background-color: hsl(var(--twc-background-white));
  color: hsl(var(--twc-foreground-neutral));
}

.link-menu {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 2px solid hsl(var(--twc-background-neutralDark));
  border-radius: 4px;
  background-color: hsl(var(--twc-background-white));
}

.menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  border: 0;
  border-radius: 4px;
  background: transparent;
  font-family: var(--twc-fontFamily-sans);
  font-size: 2.2rem;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  &.is-active {
    background-color: hsl(var(--twc-background-neutralDark));
    color: hsl(var(--twc-foreground-black));
  }
}

/* Basic editor styles */
.ProseMirror {
  margin-top: 4px;
  padding: 4px 8px 8px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: auto;
  font-weight: 300;
  z-index: 5 !important;

  p {
    font-size: 1.6rem !important;
  }

  &:focus,
  &-focused {
    border-color: var(--twc-foreground-brand);
    outline: none;
  }

  > * + * {
    margin-top: 16px;
  }

  ul {
    list-style: disc;

    li {
      margin-left: 1em;
    }
  }

  ol {
    list-style: decimal;

    li {
      margin-left: 1em;
    }
  }

  @media (min-width: 1024px) {
    max-height: 520px;
  }
}