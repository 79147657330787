@import '../../../theme/theme';

.container { 
  position: relative;
}

.select {
  @include formElementBorder();
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 14px 45px 13px $default-horizontal-padding;
  font-size: $font-standard;
  letter-spacing: $letter-spacing-standard;
  font-weight: $font-weight-light;
  max-width: $form-grow-max-width;
  background-color: $white;
  color: $grey-900;
}

.select:focus {
    @include formElementFocusState();
  }

.select::-ms-expand {
    display: none;
  }

.select:disabled {
    @include formElementDisabledState();
  }

.selectInvalid {
  @extend .select;
  @include formElementInvalidState();
}

.selectInvalid:focus {
    @include formElementFocusState();
  }

.extraTopMargin {
  margin-top: $grid-row-height * 2
}

.fullWidth {
  width: 100%;
}

.icon {
  position: absolute;
  pointer-events: none;
  text-align: right;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.icon svg { 
    width: 25px;
    height: 100%;
  }
